.about-con2 {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;

    .top-con {
        margin-bottom: 128px;
        width: 1100px;
        margin: auto;
        min-width: 1100px;
        margin-bottom: 50px;

        .title {
            text-align: center;
            font-size: 40px;
            font-weight: 500;
            color: rgb(164, 0, 49);
            margin-bottom: 40px;
            margin-top: 80px;
        }

        .section-item-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .item {
                width: 220px;
                height: 364px;
                display: flex;
                align-items: center;
                flex-direction: column;
                border: 1px solid rgb(228, 228, 228);
                padding: 47px 18px;

                .icon {
                    width: 64px;
                    margin-bottom: 42px;

                    img {
                        width: 64px;
                    }
                }

                .title2 {
                    font-size: 16px;
                    font-weight: 700;
                    color: rgb(45, 53, 63);
                    line-height: 24px;
                    margin-bottom: 41px;
                }

                .desc {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgb(131, 137, 146);
                    line-height: 24px;
                    text-align: center;
                }
            }
        }
    }

    .signature-content {
        width: 100%;
        background: rgb(247, 248, 250);
        padding: 70px 0;

        .wrap-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 1100px;
            margin: auto;
            min-width: 1100px;

            .left {
                .item {
                    .item-title {
                        color: rgb(0, 0, 0);
                        font-size: 20px;
                        margin-bottom: 15px;
                        font-weight: 700;
                    }

                    .detail {
                        margin-bottom: 60px;
                        color: rgb(131, 137, 146);
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.about-con2 {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
}

.about-con2 .top-con {
    margin-bottom: 128px;
    width: 1100px;
    margin: auto;
    min-width: 1100px;
    margin-bottom: 50px;
}

.about-con2 .top-con .title {
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    color: rgb(164, 0, 49);
    margin-bottom: 40px;
    margin-top: 80px;
}

.about-con2 .top-con .section-item-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.about-con2 .top-con .section-item-box .item {
    width: 220px;
    height: 364px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid rgb(228, 228, 228);
    padding: 47px 18px;
}

.about-con2 .top-con .section-item-box .item .icon {
    width: 64px;
    margin-bottom: 42px;
}

.about-con2 .top-con .section-item-box .item .icon img {
    width: 64px;
}

.about-con2 .top-con .section-item-box .item .title2 {
    font-size: 16px;
    font-weight: 700;
    color: rgb(45, 53, 63);
    line-height: 24px;
    margin-bottom: 41px;
}

.about-con2 .top-con .section-item-box .item .desc {
    font-size: 16px;
    font-weight: 400;
    color: rgb(131, 137, 146);
    line-height: 24px;
    text-align: center;
}

.about-con2 .signature-content {
    width: 100%;
    background: rgb(247, 248, 250);
    padding: 70px 0;
}

.about-con2 .signature-content .wrap-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1100px;
    margin: auto;
    min-width: 1100px;
}

.about-con2 .signature-content .wrap-content .left .item .item-title {
    color: rgb(0, 0, 0);
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 700;
}

.about-con2 .signature-content .wrap-content .left .item .detail {
    margin-bottom: 60px;
    color: rgb(131, 137, 146);
    font-size: 14px;
}


.info-tittle2 {
    width: 100%;
    height: 450px;
    background: url("../../../public/signature-banner.png") no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .tittle {
        font-size: 42px;
        font-weight: 400;
        color: rgb(255, 255, 255);
        width: 100%;
        text-align: center;
    }

    .doc {
        font-size: 24px;
        font-weight: 400;
        color: hsla(0, 0%, 100%, .7);
        line-height: 24px;
        margin-top: 26px;
    }
}

.small-wrap {
    position: relative;
    padding: 100px 0 260px;
    width: 1100px;
    margin: auto;
    min-width: 1100px;

    .about-text {
        width: 50%;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 19px 1px;
        padding: 76px 88px;
        text-align: left;
        position: relative;
        z-index: 1;
        background: rgb(255, 255, 255);

        .about-title {
            font-size: 26px;
            color: rgb(34, 34, 34);
            font-weight: 400;
            margin-bottom: 32px;
        }

        .about-desc {
            font-size: 16px;
            color: rgb(131, 131, 131);
            font-weight: 500;
            line-height: 24px;
        }
    }

    .about-bg {
        text-align: right;
        position: absolute;
        z-index: 0;
        transform: translateY(-200px);

        img {
            width: 60%;
        }
    }
}

@keyframes slideInTop {
    0% {
        opacity: 0;
        transform: translateY(-50px); /* 初始位置 */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* 最终位置 */
    }
}

@keyframes slideInBottom {
    0% {
        opacity: 0;
        transform: translateY(50px); /* 初始位置 */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* 最终位置 */
    }
}

.tittle, .doc {
    animation: slideInTop 1s ease forwards; /* 使用slideInTop动画，持续1秒，缓动效果，动画结束后保持最终状态 */
}

.small-wrap {
    /*position: relative;*/
    /*padding: 100px 0 260px;*/
    /*width: 1100px;*/
    /*margin: auto;*/
    /*min-width: 1100px;*/
    animation: slideInBottom 1s ease forwards; /* 使用slideInBottom动画，持续1秒，缓动效果，动画结束后保持最终状态 */
}


@media (max-width: 1651px) and (orientation: portrait) {
    .about-con2 {
        width: 100vw!important;
        box-sizing: border-box;
    }

    .about-con2 .info-tittle2 {
        height: 65vw !important;
    }

    .about-con2 .info-tittle2 .tittle {
        font-size: 5.875vw !important;
        color: #ffffff;
    }

    .about-con2 .info-tittle2 .doc {
        font-size: 3vw !important;
        color: rgba(255, 255, 255, 0.7);
    }

    .about-con2 .top-con {
        width: 100vw !important;
        min-width: 100vw !important;
        margin-bottom: 2.89vw !important;
    }

    .about-con2 .top-con .title {
        font-size: 3.6364vw !important;
        margin-bottom: 3.6364vw !important;
        margin-top: 7.2727vw !important;
    }

    .about-con2 .top-con .section-item-box {
        padding: 0 1.5vw;
        box-sizing: border-box;
    }

    .about-con2 .top-con .section-item-box .item {
        width: 19.6818vw !important;
        height: 56.6364vw !important;
        border: 0.0909vw solid rgb(228, 228, 228) !important;
        padding: 4.2727vw 1.6364vw !important;
    }

    .about-con2 .top-con .section-item-box .item .icon {
        width: 5.818vw !important;
        margin-bottom: 0vw !important;
    }

    .about-con2 .top-con .section-item-box .item .icon img {
        width: 5.818vw !important;
    }

    .about-con2 .top-con .section-item-box .item .title2 {
        font-size: 1.455vw !important;
        margin-bottom: 2.366vw !important;
    }

    .about-con2 .top-con .section-item-box .item .desc {
        font-size: 1.455vw !important;
    }

    .about-con2 .signature-content .wrap-content {
        width: 100vw !important;
        min-width: 100vw !important;
        box-sizing: border-box;
    }

    .about-con2 .signature-content .wrap-content .left {
        padding-left: 3vw!important;
    }

    .about-con2 .signature-content .wrap-content .left .item .item-title {
        font-size: 3vw !important;
        margin-bottom: 0.78125vw !important;
    }

    .about-con2 .signature-content .wrap-content .right img {
        width: 50vw !important;
        margin-left: 5vw !important;
    }
}
