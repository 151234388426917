.about-con2{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    .info-tittle{
        width: 99vw;
        height: 450px;
        background: url("../../../public/safe-banner.png")
        no-repeat
        center
        center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .tittle{
            font-size: 42px;
            font-weight: 400;
            color: rgb(255, 255, 255);
            width: 100%;
            text-align: center;
        }
        .doc{
            font-size: 24px;
            font-weight: 400;
            color: hsla(0, 0%, 100%, .7);
            line-height: 24px;
            margin-top: 26px;
        }
    }
    .safe-content{
        padding: 30px 0;
        width: 1100px;
        margin: auto;
        min-width: 1100px;
        .content-item{
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 60px;
            .desc-section{
                width: 550px;
                height: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                .title{
                    font-size: 30px;
                    color: #a40031;
                    font-weight: 500;
                    line-height: 24px;
                    margin-bottom: 23px;
                }
                .sub-box{
                    .sub-title{
                        font-size: 20px;
                        margin-top: 25px;
                        margin-bottom: 10px;
                        font-weight: 700;
                        color: #000000A6;
                        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
                    }
                    .desc{
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 400;
                        color: #3E454D;
                    }
                }
            }
            .img-box{
                flex: 1;
                box-sizing: border-box;
                width: 45%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .small-wrap{
        position: relative;
        padding: 100px 0 260px;
        width: 1100px;
        margin: auto;
        min-width: 1100px;
        .about-text{
            width: 50%;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 19px 1px;
            padding: 76px 88px;
            text-align: left;
            position: relative;
            z-index: 1;
            background: rgb(255, 255, 255);
            .about-title{
                font-size: 26px;
                color: rgb(34, 34, 34);
                font-weight: 400;
                margin-bottom: 32px;
            }
            .about-desc{
                font-size: 16px;
                color: rgb(131, 131, 131);
                font-weight: 500;
                line-height: 24px;
            }
        }
        .about-bg{
            text-align: right;
            position: absolute;
            z-index: 0;
            transform: translateY(-200px);
            img{
                width: 60%;
            }
        }
    }
}

.about-con2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
}

.about-con2 .info-tittle {
    width: 99vw;
    height: 450px;
    background: url("../../../public/safe-banner.png") no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-con2 .info-tittle .tittle {
    font-size: 42px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    width: 100%;
    text-align: center;
}

.about-con2 .info-tittle .doc {
    font-size: 24px;
    font-weight: 400;
    color: hsla(0, 0%, 100%, .7);
    line-height: 24px;
    margin-top: 26px;
}

.about-con2 .safe-content {
    padding: 30px 0;
    width: 1100px;
    margin: auto;
    min-width: 1100px;
}

.about-con2 .safe-content .content-item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 60px;
}

.about-con2 .safe-content .content-item .desc-section {
    width: 550px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.about-con2 .safe-content .content-item .desc-section .title {
    font-size: 30px;
    color: #a40031;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 23px;
}

.about-con2 .safe-content .content-item .desc-section .sub-box .sub-title {
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 10px;
    font-weight: 700;
    color: #000000A6;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.about-con2 .safe-content .content-item .desc-section .sub-box .desc {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #3E454D;
}

.about-con2 .safe-content .content-item .img-box {
    flex: 1;
    box-sizing: border-box;
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-con2 .small-wrap {
    position: relative;
    padding: 100px 0 260px;
    width: 1100px;
    margin: auto;
    min-width: 1100px;
}

.about-con2 .small-wrap .about-text {
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 19px 1px;
    padding: 76px 88px;
    text-align: left;
    position: relative;
    z-index: 1;
    background: rgb(255, 255, 255);
}

.about-con2 .small-wrap .about-text .about-title {
    font-size: 26px;
    color: rgb(34, 34, 34);
    font-weight: 400;
    margin-bottom: 32px;
}

.about-con2 .small-wrap .about-text .about-desc {
    font-size: 16px;
    color: rgb(131, 131, 131);
    font-weight: 500;
    line-height: 24px;
}

.about-con2 .small-wrap .about-bg {
    text-align: right;
    position: absolute;
    z-index: 0;
    transform: translateY(-200px);
}

.about-con2 .small-wrap .about-bg img {
    width: 60%;
}


@keyframes slideInTop {
    0% {
        opacity: 0;
        transform: translateY(-50px); /* 初始位置 */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* 最终位置 */
    }
}

@keyframes slideInBottom {
    0% {
        opacity: 0;
        transform: translateY(50px); /* 初始位置 */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* 最终位置 */
    }
}

.tittle,.doc {
    animation: slideInTop 1s ease forwards; /* 使用slideInTop动画，持续1秒，缓动效果，动画结束后保持最终状态 */
}

.small-wrap {
    position: relative;
    padding: 100px 0 260px;
    width: 1100px;
    margin: auto;
    min-width: 1100px;
    animation: slideInBottom 1s ease forwards; /* 使用slideInBottom动画，持续1秒，缓动效果，动画结束后保持最终状态 */
}
@media (max-width: 1651px) and (orientation: portrait) {
    .about-con2 .info-tittle {
        height: 65vw !important;
    }

    .about-con2 .info-tittle .tittle {
        font-size: 5.875vw !important;
    }

    .about-con2 .info-tittle .doc {
        font-size: 3vw !important;
    }

    .about-con2 .safe-content {
        padding: 2.0833vw 8vw;
        width: 100vw!important;
        min-width: 100vw!important;
        box-sizing: border-box;
    }

    .about-con2 .safe-content .content-item {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 4.1667vw;
    }

    .about-con2 .safe-content .content-item .desc-section {
        width: 36.4583vw;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .about-con2 .safe-content .content-item .desc-section .title {
        font-size: 3vw;
        color: #a40031;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 1.4375vw;
    }

    .about-con2 .safe-content .content-item .desc-section .sub-box .sub-title {
        font-size: 1.25vw;
        margin-top: 1.5625vw;
        margin-bottom: 0.625vw;
        font-weight: 700;
        color: #000000A6;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    }

    .about-con2 .safe-content .content-item .desc-section .sub-box .desc {
        font-size: 1vw;
        font-weight: 400;
        color: #3E454D;
    }

    .about-con2 .safe-content .img-box {
        flex: 1 1;
        box-sizing: border-box;
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .about-con2 .safe-content .img-box img {
        width: 41%;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 0.625vw;
    }
}
