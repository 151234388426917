.cases-container {
    width: 100vw;
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    margin-bottom: 1vw;
}

.cases-container .cases-top-con {
    background: url("../../../public/case-banner.png") no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 450px;
}

.cases-container .cases-top-con .cases-top-text {
    font-size: 42px;
    font-weight: 400;
    color: #fff;
    width: 100%;
    text-align: center;
}

.cases-container .small-content {
    width: 1100px;
    display: flex;
    justify-content: space-between;
    margin-top: 34px;
}

.cases-container .small-content .left {
    width: 140px;
    height: 470px;
    background-color: #f6f6f6;
}

.cases-container .small-content .left .menu2 {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.cases-container .small-content .left .menu-item-text {
    color: #635e5e;
    font-size: 18px;
    padding: 10px;
}

.cases-container .small-content .left .menu-item-text.active {
    color: #a40031;
    position: relative;
}

.cases-container .small-content .left .menu-item-text.active::after {
    content: '';
    display: inline-block;
    height: 20px;
    width: 2px;
    background: #a40031;
    position: absolute;
    right: 2px;
    top: 12px;
}

.cases-container .small-content .left .menu-item-text.cursor {
    cursor: pointer;
}

.cases-container .small-content .right {
    width: 935px;
    height: 748px;
    padding-bottom: 108px;
}

.cases-container .small-content .right .case-sub {
    width: 935px;
    height: 120px;
    margin-bottom: 32px;
    background: url("../../../public/case-sub.png");
    background-size: cover;
    color: #3e454d;
    font-size: 26px;
    font-weight: 500;
    min-height: 1px;
    text-align: center;
    line-height: 120px;
}

.cases-container .small-content .right .substance {
    width: 935px;
    height: 474px;
}

.cases-container .small-content .right .substance .finance-tech {
    width: 935px;
    height: 474px;
}

.cases-container .small-content .right .substance .finance-tech .tittle {
    margin-top: 0;
    color: rgba(0, 0, 0, .85);
    font-weight: 700;
    margin-bottom: 8px;
}

.cases-container .small-content .right .substance .finance-tech .paragraph {
    margin-top: 0;
    margin-bottom: 1em;
    color: #2c3e50;
    font-size: 14px;
}

.cases-container .small-content .right .substance .finance-tech .number-contents {
    width: 935px;
    display: flex;
}

.cases-container .small-content .right .substance .finance-tech .number {
    background: #a40031;
    display: inline-block;
    width: 22px;
    height: 22px;
    line-height: 22px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    margin-right: 10px;
}

.cases-container .small-content .right .substance .finance-tech .paragraph2 {
    width: 900px;
    margin-top: 0;
    margin-bottom: 1em;
    color: #2c3e50;
    font-size: 14px;
}

.cases-container .small-content .right .substance .finance-tech .tittle2 {
    margin-bottom: .5em;
    color: rgba(0, 0, 0, .85);
    font-weight: 700;
    margin-top: 30px;
    font-size: 14px;
}

@keyframes slideFromTop {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideFromBottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.cases-top-text {
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.solutions-box {
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}

.cases-top-text {
    animation-name: slideFromTop;
}

.solutions-box {
    animation-name: slideFromBottom;
}
@media (max-width: 1651px) and (orientation: portrait) {
    .menu2 {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
    }
    .cases-container {
        width: 100vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #fff;
        margin-bottom: 97vw;
        box-sizing: border-box;
    }

    .cases-top-con {
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 65vw!important;
    }

    .cases-top-con .cases-top-text {
        font-size: 5.875vw!important;
        font-weight: 400;
        color: #fff;
        width: 100%;
        text-align: center;
    }

    .small-content {
        width: 100vw!important;
        height: 59.96875vw!important;
        display: flex;
        justify-content: space-between;
        margin-top: 2.0833vw!important;
    }

    .small-content .left {
        width: 20.75vw !important;
        height: 59.3021vw !important;
        background-color: #f6f6f6;
    }

    .small-content .left .menu-item-text {
        font-size: 3vw!important;
        padding: 0.625vw!important;
    }

    .small-content .left .menu2 {
        width: 15.625vw;
        height: 100%;
        white-space: nowrap;
        margin: 0 3.125vw;
        flex: 1 1;
        display: inline-flex;
        list-style-type: none;
        padding: 0;
    }

    .small-content .right {
        width: 79.4375vw!important;
        height: 59.3021vw !important;
        padding-bottom: 6.75vw!important;
    }

    .small-content .right .case-sub {
        width: 79vw!important;
        height: 15vw!important;
        margin-bottom: 2vw!important;
        background-size: cover;
        color: #3e454d;
        font-size: 3.625vw!important;
        font-weight: 500;
        min-height: 1px!important;
        text-align: center;
        line-height: 15vw!important;
    }

    .small-content .right .substance {
        width: 58.4375vw!important;
        height: 29.625vw!important;
    }

    .small-content .right .substance .finance-tech {
        width: 58.4375vw!important;
        height: 29.625vw!important;
    }

    .small-content .right .substance .finance-tech .tittle {
        margin-top: 0;
        color: rgba(0, 0, 0, .85);
        font-weight: 700;
        margin-bottom: 0.6667vw;
        font-size: 2.8vw;
        width: 70vw;
    }

    .small-content .right .substance .finance-tech .paragraph {
        margin-top: 0;
        margin-bottom: 0.6667vw!important;
        color: #2c3e50;
        font-size: 3vw!important;
        width: 70vw;
    }
}
