/*.consulting-con {*/
/*    width: 100%;*/
/*    height: 100%;*/

/*    .substance {*/
/*        width: 100%;*/
/*        height: 100%;*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        justify-content: center;*/
/*        align-items: center;*/

/*        .substance-box2 {*/
/*            width: 1100px;*/
/*            margin: auto;*/
/*            min-width: 1100px;*/

/*            .content-title {*/
/*                font-size: 40px;*/
/*                font-weight: 500;*/
/*                color: #a40031;*/
/*                line-height: 24px;*/
/*                margin-bottom: 53px;*/
/*                text-align: center;*/
/*            }*/

/*            .item-box {*/
/*                margin-bottom: 127px;*/
/*                display: flex;*/
/*                justify-content: space-around;*/

/*                .item {*/
/*                    width: 275px;*/
/*                    border: 1px solid #e4e4e4;*/
/*                    padding: 47px 18px;*/
/*                    flex-direction: column;*/
/*                    display: flex;*/
/*                    align-items: center;*/
/*                    margin-right: 20px;*/
/*                    margin-bottom: 20px;*/

/*                    img {*/
/*                        width: 64px;*/
/*                        margin-bottom: 42px;*/
/*                        vertical-align: middle;*/
/*                        border-style: none;*/
/*                    }*/

/*                    .title {*/
/*                        font-size: 16px;*/
/*                        font-weight: 400;*/
/*                        color: #2d353f;*/
/*                        line-height: 24px;*/
/*                        margin-bottom: 41px;*/
/*                    }*/

/*                    .desc {*/
/*                        font-size: 16px;*/
/*                        font-weight: 400;*/
/*                        color: #838992;*/
/*                        line-height: 24px;*/
/*                        text-align: center;*/
/*                    }*/
/*                }*/
/*            }*/

/*            .small-wrap {*/
/*                width: 1100px;*/
/*                margin: auto;*/
/*                min-width: 1100px;*/

/*                img {*/
/*                    width: 100%;*/
/*                }*/
/*            }*/

/*            .custom-div {*/
/*                width: 1903px;*/
/*                margin-left: -21vw;*/
/*                background: #f7f8fa;*/
/*                text-align: center;*/
/*                display: flex;*/
/*                justify-content: center;*/

/*                .process {*/
/*                    background: #f7f8fa;*/
/*                    padding: 103px 0;*/
/*                    width: 50%;*/

/*                    .small-wrap {*/
/*                        width: 1100px;*/
/*                        margin: auto;*/
/*                        min-width: 1100px;*/
/*                    }*/

/*                    .process-title {*/
/*                        padding-bottom: 33px;*/
/*                        font-size: 40px;*/
/*                        font-weight: 500;*/
/*                        color: #a40031;*/
/*                        line-height: 24px;*/
/*                        margin-bottom: 53px;*/
/*                        text-align: center;*/
/*                    }*/

/*                    .process-text {*/
/*                        align-items: center;*/
/*                        text-align: left;*/
/*                        display: flex;*/
/*                        flex-flow: row wrap;*/
/*                        justify-content: space-around;*/

/*                        .process-item {*/
/*                            display: flex;*/
/*                            align-items: center;*/

/*                            img {*/
/*                                width: 64px;*/
/*                                margin-right: 5px;*/
/*                            }*/

/*                            .item-text {*/
/*                                color: #2c3e50;*/
/*                                font-size: 14px;*/
/*                            }*/
/*                        }*/
/*                    }*/
/*                }*/
/*            }*/

/*            .foot-content {*/
/*                padding: 110px 0;*/
/*                width: 1100px;*/
/*                margin: auto;*/
/*                min-width: 1100px;*/

/*                .foot-title {*/
/*                    padding-bottom: 70px;*/
/*                    font-size: 40px;*/
/*                    font-weight: 500;*/
/*                    color: #a40031;*/
/*                    line-height: 24px;*/
/*                    margin-bottom: 53px;*/
/*                    text-align: center;*/
/*                }*/

/*                .item-foot {*/
/*                    display: flex;*/
/*                    justify-content: space-around;*/

/*                    .item {*/
/*                        width: 286px;*/
/*                        flex-direction: column;*/
/*                        display: flex;*/
/*                        align-items: center;*/

/*                        img {*/
/*                            width: 64px;*/
/*                            margin-bottom: 42px;*/
/*                            vertical-align: middle;*/
/*                            border-style: none;*/
/*                        }*/

/*                        .desc {*/
/*                            font-size: 16px;*/
/*                            font-weight: 400;*/
/*                            color: #838992;*/
/*                            line-height: 24px;*/
/*                            text-align: center;*/
/*                        }*/
/*                    }*/
/*                }*/
/*            }*/
/*        }*/

/*        .substance-box1 {*/
/*            width: 1100px;*/
/*            margin: auto;*/
/*            min-width: 1100px;*/

/*            .content-title {*/
/*                font-size: 40px;*/
/*                font-weight: 500;*/
/*                color: #a40031;*/
/*                line-height: 24px;*/
/*                margin-bottom: 53px;*/
/*                text-align: center;*/
/*            }*/

/*            .item-box {*/
/*                margin-bottom: 127px;*/
/*                display: flex;*/
/*                justify-content: space-between;*/

/*                .item {*/
/*                    width: 214.5px;*/
/*                    border: 1px solid #e4e4e4;*/
/*                    padding: 47px 18px;*/
/*                    flex-direction: column;*/
/*                    display: flex;*/
/*                    align-items: center;*/
/*                    box-sizing: border-box;*/

/*                    img {*/
/*                        width: 64px;*/
/*                        margin-bottom: 42px;*/
/*                        vertical-align: middle;*/
/*                        border-style: none;*/
/*                    }*/

/*                    .title {*/
/*                        font-size: 16px;*/
/*                        font-weight: 400;*/
/*                        color: #2d353f;*/
/*                        line-height: 24px;*/
/*                        margin-bottom: 41px;*/
/*                        text-align: center;*/
/*                    }*/

/*                    .desc {*/
/*                        font-size: 16px;*/
/*                        font-weight: 400;*/
/*                        color: #838992;*/
/*                        line-height: 24px;*/
/*                        text-align: center;*/
/*                    }*/
/*                }*/
/*            }*/

/*            .small-wrap {*/
/*                width: 1100px;*/
/*                margin: auto;*/
/*                min-width: 1100px;*/

/*                img {*/
/*                    width: 100%;*/
/*                }*/
/*            }*/

/*            .custom-div {*/
/*                width: 1903px;*/
/*                margin-left: -21vw;*/
/*                background: #f7f8fa;*/
/*                text-align: center;*/
/*                display: flex;*/
/*                justify-content: center;*/

/*                .process {*/
/*                    background: #f7f8fa;*/
/*                    padding: 103px 0;*/
/*                    width: 50%;*/

/*                    .small-wrap {*/
/*                        width: 1100px;*/
/*                        margin: auto;*/
/*                        min-width: 1100px;*/
/*                    }*/

/*                    .process-title {*/
/*                        padding-bottom: 33px;*/
/*                        font-size: 40px;*/
/*                        font-weight: 500;*/
/*                        color: #a40031;*/
/*                        line-height: 24px;*/
/*                        margin-bottom: 53px;*/
/*                        text-align: center;*/
/*                    }*/

/*                    .process-text {*/
/*                        align-items: center;*/
/*                        text-align: left;*/
/*                        display: flex;*/
/*                        flex-flow: row wrap;*/
/*                        justify-content: space-around;*/

/*                        .process-item {*/
/*                            display: flex;*/
/*                            align-items: center;*/

/*                            img {*/
/*                                width: 64px;*/
/*                                margin-right: 5px;*/
/*                            }*/

/*                            .item-text {*/
/*                                color: #2c3e50;*/
/*                                font-size: 14px;*/
/*                            }*/
/*                        }*/
/*                    }*/
/*                }*/
/*            }*/

/*            .foot-content {*/
/*                padding: 110px 0;*/
/*                width: 1100px;*/
/*                margin: auto;*/
/*                min-width: 1100px;*/

/*                .foot-title {*/
/*                    padding-bottom: 70px;*/
/*                    font-size: 40px;*/
/*                    font-weight: 500;*/
/*                    color: #a40031;*/
/*                    line-height: 24px;*/
/*                    margin-bottom: 53px;*/
/*                    text-align: center;*/
/*                }*/

/*                .item-foot {*/
/*                    display: flex;*/
/*                    justify-content: space-around;*/

/*                    .item {*/
/*                        width: 286px;*/
/*                        flex-direction: column;*/
/*                        display: flex;*/
/*                        align-items: center;*/

/*                        img {*/
/*                            width: 64px;*/
/*                            margin-bottom: 42px;*/
/*                            vertical-align: middle;*/
/*                            border-style: none;*/
/*                        }*/

/*                        .desc {*/
/*                            font-size: 16px;*/
/*                            font-weight: 400;*/
/*                            color: #838992;*/
/*                            line-height: 24px;*/
/*                            text-align: center;*/
/*                        }*/
/*                    }*/
/*                }*/
/*            }*/
/*        }*/

/*        .one-box {*/
/*            width: 1100px;*/
/*            margin: auto;*/
/*            min-width: 1100px;*/

/*            .content-title {*/
/*                font-size: 40px;*/
/*                font-weight: 500;*/
/*                color: #a40031;*/
/*                line-height: 24px;*/
/*                margin-bottom: 53px;*/
/*                text-align: center;*/
/*            }*/

/*            .item-box {*/
/*                margin-bottom: 127px;*/
/*                display: flex;*/
/*                justify-content: space-around;*/

/*                .item {*/
/*                    width: 286px;*/
/*                    border: 1px solid #e4e4e4;*/
/*                    padding: 47px 18px;*/
/*                    flex-direction: column;*/
/*                    display: flex;*/
/*                    align-items: center;*/

/*                    img {*/
/*                        width: 64px;*/
/*                        margin-bottom: 42px;*/
/*                        vertical-align: middle;*/
/*                        border-style: none;*/
/*                    }*/

/*                    .title {*/
/*                        font-size: 16px;*/
/*                        font-weight: 400;*/
/*                        color: #2d353f;*/
/*                        line-height: 24px;*/
/*                        padding-top: 39px;*/
/*                        text-align: center;*/
/*                    }*/

/*                    .desc {*/
/*                        font-size: 16px;*/
/*                        font-weight: 400;*/
/*                        color: #838992;*/
/*                        line-height: 24px;*/
/*                        padding-top: 42px;*/
/*                        text-align: center;*/
/*                    }*/
/*                }*/
/*            }*/
/*        }*/

/*        .small-wrap {*/
/*            width: 1100px;*/
/*            margin: auto;*/
/*            min-width: 1100px;*/

/*            img {*/
/*                width: 100%;*/
/*            }*/
/*        }*/

/*        .process {*/
/*            background: #f7f8fa;*/
/*            padding: 103px 0;*/
/*            width: 100%;*/

/*            .small-wrap {*/
/*                width: 1100px;*/
/*                margin: auto;*/
/*                min-width: 1100px;*/

/*                .process-title {*/
/*                    padding-bottom: 33px;*/
/*                    font-size: 40px;*/
/*                    font-weight: 500;*/
/*                    color: #a40031;*/
/*                    line-height: 24px;*/
/*                    margin-bottom: 53px;*/
/*                    text-align: center;*/
/*                }*/

/*                .process-text {*/
/*                    align-items: center;*/
/*                    text-align: left;*/
/*                    display: flex;*/
/*                    flex-flow: row wrap;*/
/*                    justify-content: space-evenly;*/

/*                    .process-item {*/
/*                        display: flex;*/
/*                        align-items: center;*/

/*                        img {*/
/*                            width: 64px;*/
/*                            margin-right: 5px;*/
/*                        }*/

/*                        .item-text {*/
/*                            color: #2c3e50;*/
/*                            font-size: 14px;*/
/*                            text-align: left;*/

/*                            .process-item {*/
/*                                display: flex;*/
/*                                align-items: center;*/

/*                                img {*/
/*                                    width: 64px;*/
/*                                    height: 64px;*/
/*                                    margin-right: 5px;*/
/*                                }*/
/*                            }*/
/*                        }*/
/*                    }*/
/*                }*/
/*            }*/
/*        }*/

/*        .item-box-last {*/
/*            padding: 110px 0;*/
/*            width: 1100px;*/
/*            margin: auto;*/
/*            min-width: 1100px;*/

/*            .foot-title {*/
/*                padding-bottom: 70px;*/
/*                font-size: 40px;*/
/*                font-weight: 500;*/
/*                color: #a40031;*/
/*                line-height: 24px;*/
/*                margin-bottom: 53px;*/
/*                text-align: center;*/
/*            }*/

/*            .item-foot {*/
/*                display: flex;*/
/*                justify-content: space-around;*/

/*                .item {*/
/*                    width: 286px;*/
/*                    flex-direction: column;*/
/*                    display: flex;*/
/*                    align-items: center;*/

/*                    img {*/
/*                        width: 64px;*/
/*                        margin-bottom: 42px;*/
/*                        vertical-align: middle;*/
/*                        border-style: none;*/
/*                    }*/

/*                    .title {*/
/*                        font-size: 16px;*/
/*                        font-weight: 400;*/
/*                        color: #2d353f;*/
/*                        line-height: 24px;*/
/*                        margin-bottom: 41px;*/
/*                        text-align: center;*/
/*                    }*/

/*                    .desc {*/
/*                        font-size: 16px;*/
/*                        font-weight: 400;*/
/*                        color: #838992;*/
/*                        line-height: 24px;*/
/*                        text-align: center;*/
/*                    }*/
/*                }*/
/*            }*/
/*        }*/
/*    }*/

/*    .consulting-top-con {*/
/*        width: 100%;*/
/*        height: 450px;*/
/*        background: url("../../../public/consulting-banner.png") no-repeat center center;*/
/*        background-size: cover;*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        justify-content: center;*/
/*        align-items: center;*/
/*    }*/

/*    .consulting-top-text {*/
/*        color: #fff;*/
/*        font-size: 42px;*/
/*        font-weight: 400;*/
/*        margin-bottom: 32px;*/
/*    }*/

/*    .consulting-top-text2 {*/
/*        font-size: 24px;*/
/*        font-weight: 400;*/
/*        line-height: 24px;*/
/*        margin-top: 26px;*/
/*        color: rgba(255, 255, 255, 0.7);*/
/*    }*/

/*    .nav {*/
/*        border-bottom: 1px solid rgb(235, 235, 235);*/
/*        margin-bottom: 110px;*/

/*        .small-wrap {*/
/*            width: 1100px;*/
/*            margin: auto;*/
/*            min-width: 1100px;*/
/*            display: flex;*/

/*            .nav-item {*/
/*                padding: 37px 0px;*/
/*                margin-right: 52px;*/
/*                position: relative;*/
/*            }*/

/*            .active {*/
/*                color: #a40031;*/
/*            }*/

/*            .cursor {*/
/*                cursor: pointer;*/
/*            }*/

/*            .item-title {*/
/*                color: rgb(99, 94, 94);*/
/*                font-size: 20px;*/
/*                font-weight: 400;*/
/*                line-height: 24px;*/
/*            }*/

/*            .item-title:hover {*/
/*                color: #a40031;*/
/*            }*/

/*            .line {*/
/*                bottom: 0px;*/
/*                position: absolute;*/
/*                left: 0px;*/
/*                right: 0px;*/
/*                height: 2px;*/
/*                background: rgb(164, 0, 49);*/
/*            }*/
/*        }*/
/*    }*/
/*}*/

.consulting-con {
    width: 100%;
    height: 100%;
}

#img1 img {
    /*width: 50%;*/
    width: 960px;
}

#item-text2 {
    display: none;
}

#desc2 {
    display: none;
}
#title2{
    display: none;
}
.consulting-con .substance {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*.consulting-con .substance-box2,*/
/*.consulting-con .substance-box1,*/
/*.consulting-con .one-box {*/
/*    width: 1100px;*/
/*    margin: auto;*/
/*    min-width: 1100px;*/
/*}*/

.consulting-con .substance-box2,
.consulting-con .substance-box1,
.consulting-con .one-box {
    width: 100vw;
    /*max-width: 1100px; !* Limit maximum width to 1100px *!*/
    margin: auto; /* Center the elements */
    box-sizing: border-box; /* Include padding and border in the element's total width */
}


.consulting-con .content-title {
    font-size: 40px;
    font-weight: 500;
    color: #a40031;
    line-height: 24px;
    margin-bottom: 53px;
    text-align: center;
}

.consulting-con .item-box {
    margin-bottom: 127px; /* Bottom margin */
    display: flex;
    justify-content: space-around;
    /*max-width: 50vw; !* Limit maximum width to 50vw *!*/
    max-width: 1100px; /* Limit maximum width to 50vw */
    margin-left: auto; /* Horizontally center the element */
    margin-right: auto; /* Horizontally center the element */
    box-sizing: border-box; /* Include padding and border in the element's total width */
}

.consulting-con .substance-box1 .item-box {
    width: 80vw !important;
    /*max-width: 100vw !important;*/
    max-width: 1100px !important;
}

.consulting-con .substance-box1 .item-box .item {
    /*width: 14vw !important;*/
    width: 35vw !important;
    margin-right: 1vw;
    margin-left: 1vw;
}

.consulting-con .item {
    width: 275px;
    border: 1px solid #e4e4e4;
    padding: 47px 18px;
    flex-direction: column;
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
}

.process .process-item img {
    margin-bottom: 0 !important;
}

.consulting-con img {
    width: 64px;
    margin-bottom: 42px;
    vertical-align: middle;
    border-style: none;
}

.consulting-con .title {
    font-size: 16px;
    font-weight: 400;
    color: #2d353f;
    line-height: 24px;
    margin-bottom: 41px;
}

.consulting-con .desc {
    font-size: 16px;
    font-weight: 400;
    color: #838992;
    line-height: 24px;
    text-align: center;
}

.consulting-con .small-wrap {
    width: 100vw;
    margin: auto;
    /*min-width: 100vw;*/
    padding: 0 !important;
    display: flex;
    /*justify-content: center;*/
    align-content: center;
    justify-content: space-around;
}

.consulting-con .small-wrap .process-title {
    display: flex;
    align-items: center;
}

#pro1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#pro1 .process-title {
    font-size: 40px;
    font-weight: 500;
    color: #a40031;
    line-height: 24px;
    margin-bottom: 53px;
    text-align: center;
}

#menu1 {
    width: 1100px!important;
}

.consulting-con .substance-box1 .process .process-title,
.consulting-con .substance-box2 .custom-div .process-title {
    font-size: 40px;
    font-weight: 500;
    color: #a40031;
    line-height: 24px;
    margin-bottom: 53px;
    text-align: center;
}

.consulting-con .small-wrap .process-text {
    display: flex;
    align-content: center;
    justify-content: space-around;
    width: 80%;
}

.consulting-con .custom-div {
    width: 100vw;
    /*margin-left: -21vw;*/
    background: #f7f8fa;
    text-align: center;
    display: flex;
    justify-content: center;
}

.consulting-con .process {
    background: #f7f8fa;
    padding: 103px 0;
    width: 100%;
    margin-bottom: 5vw;
}

.consulting-con .process-text {
    align-items: center;
    text-align: left;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    /*width: 65vw !important;*/
    width: 1100px !important;
    margin: auto;
}

.consulting-con .process-item {
    display: flex;
    align-items: center;
}

.consulting-con .item-text {
    color: #2c3e50;
    font-size: 14px;
}

.consulting-con .foot-content {
    padding: 110px 0;
    width: 1100px;
    margin: auto;
    min-width: 1100px;
}

.consulting-con .foot-title {
    padding-bottom: 70px;
    font-size: 40px;
    font-weight: 500;
    color: #a40031;
    line-height: 24px;
    margin-bottom: 53px;
    text-align: center;
}

.consulting-con .item-foot {
    display: flex;
    justify-content: space-around;
    margin-bottom: 8vw;
}

.consulting-con .item-foot .item {
    margin-right: 2vw;
}

.consulting-con .nav {
    border-bottom: 1px solid rgb(235, 235, 235);
    margin-bottom: 110px;
}

.consulting-con .consulting-top-con {
    width: 100%;
    height: 450px;
    background: url("../../../public/consulting-banner.png") no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.consulting-con .consulting-top-text {
    color: #fff;
    font-size: 42px;
    font-weight: 400;
    margin-bottom: 32px;
}

.consulting-con .consulting-top-text2 {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 26px;
    color: rgba(255, 255, 255, 0.7);
}

.consulting-con .small-wrap .nav .small-wrap {
    width: 1100px;
    margin: auto;
    min-width: 1100px;
    display: flex;
}

.consulting-con .nav-item {
    padding: 37px 0px;
    margin-right: 52px;
    position: relative;
}

.consulting-con .active {
    color: #a40031;
}

.consulting-con .cursor {
    cursor: pointer;
}

.consulting-con .item-title {
    color: rgb(99, 94, 94);
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
}

.consulting-con .item-title:hover {
    color: #a40031;
}

.consulting-con .line {
    bottom: 0px;
    position: absolute;
    left: 0px;
    right: 0px;
    height: 2px;
    background: rgb(164, 0, 49);
}


/* CSS动画效果 */
@keyframes slideDown {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.consulting-top-text {
    margin-top: -20px;
    opacity: 0;
    animation: slideDown 1s ease forwards;
}

.consulting-top-text2 {
    margin-top: -20px;
    opacity: 0;
    animation: slideDown 0.7s ease forwards;
}

.substance {
    margin-top: -20px;
    opacity: 0;
    animation: slideUp 1s ease forwards;
}

@media (max-width: 1651px) and (orientation: portrait) {
    .consulting-top-con {
        height: 65vw !important;
    }

    .consulting-top-con .consulting-top-text {
        font-size: 5.875vw !important;
        margin-bottom: 2.5vw !important;
    }

    .consulting-top-con .consulting-top-text2 {
        font-size: 3.125vw !important;
        margin-top: 1.875vw !important;
    }

    .consulting-con .substance .small-wrap img {
        width: 100vw !important;
    }

    .nav {
        margin-bottom: 5vw !important;
    }

    .nav .small-wrap .nav-item {
        padding: 3vw 0 !important;
        margin-right: 2vw !important;
        position: relative;
    }

    .nav .small-wrap .nav-item .item-title {
        color: rgb(99, 94, 94);
        font-size: 2.7vw;
        font-weight: 400;
        line-height: 1.5;
    }

    .substance {
        width: 100vw !important;
        margin-top: -1.25vw !important;
        animation: slideUp 1s ease forwards !important;
    }

    .substance .process {
        padding: 9.364vw 0 !important;
        width: 100vw !important;
    }

    #pro1 {
        display: flex;
        flex-direction: column;
    }

    .substance .process .small-wrap .process-title {
        /*padding-bottom: 0vw !important;*/
        /*font-size: 3.636vw !important;*/
        /*margin-bottom: 0vw !important;*/
        /*margin-right: 1vw !important;*/
        width: 100vw !important;
        font-size: 4.5vw !important;
        font-weight: 500;
        color: #a40031;
        line-height: 24px;
        margin-bottom: 53px;
        text-align: center;
    }

    .substance .process .small-wrap img {
        width: 5.818vw !important;
        margin-right: 0.455vw !important;
    }

    .substance .process .small-wrap .item-text {
        font-size: 2vw !important;
    }

    .substance .item-box-last {
        padding: 4vw 0 !important;
        width: 100vw !important;
        margin: auto !important;
        min-width: 100vw !important;
    }

    .substance .item-box-last .foot-title {
        padding-bottom: 6.36vw !important;
        font-size: 4.5vw !important;
        margin-bottom: 4.82vw !important;
    }

    .substance .item-box-last .item-foot .item {
        width: 22vw !important;
    }

    .substance .item-box-last .item-foot .item img {
        width: 5.818vw !important;
        margin-bottom: 3.818vw !important;
    }

    .substance .item-box-last .item-foot .item .title {
        font-size: 1.455vw !important;
        /*line-height: 2.182vw!important;*/
        margin-bottom: 3.727vw !important;
    }

    .substance .item-box-last .item-foot .item .desc {
        font-size: 1.455vw !important;
        /*line-height: 2.182vw!important;*/
    }

    .substance .substance-box1, .substance .substance-box2 {
        width: 100vw !important;
        min-width: 100vw !important;
    }

    .substance .substance-box1 .item-box, .substance .substance-box2 .item-box {
        margin-bottom: 11.545vw !important;
    }

    .substance .substance-box1 .item-box .item, .substance .substance-box2 .item-box .item {
        /*flex: 1;*/
        padding: 5vw 0 0 0 !important;
        margin: 1vw;
    }

    .substance .substance-box1 .item-box .item img, .substance .substance-box2 .item-box .item img {
        width: 5.818vw !important;
        margin-bottom: 3.818vw !important;
    }

    .substance .substance-box1 .item-box .item .title, .substance .substance-box2 .item-box .item .title {
        font-size: 3vw !important;
        /*line-height: 2.182vw!important;*/
        margin-bottom: 3.727vw !important;
    }

    .substance .substance-box1 .item-box .item .desc, .substance .substance-box2 .item-box .item .desc {
        font-size: 3vw !important;
        /*line-height: 1.5vw!important;*/
        padding-top: 2.625vw !important;
        text-align: center;
    }

    .substance .content-title {
        font-size: 4.5vw !important;
        font-weight: 500 !important;
        color: #a40031 !important;
        line-height: 1.5 !important;
        margin-bottom: 3.3125vw !important;
        text-align: center !important;
    }

    .substance .one-box {
        width: 100vw !important;
        margin: auto;
        min-width: 100vw !important;
    }

    .substance .one-box .content-title {
        font-size: 4.5vw !important;
        font-weight: 500 !important;
        color: #a40031 !important;
        line-height: 1.5 !important;
        margin-bottom: 3.3125vw !important;
        text-align: center !important;
    }

    .substance .one-box .item-box {
        margin-bottom: 7.9375vw !important;
    }

    .substance .one-box .item-box .item {
        width: 26vw !important;
        border: 0.0625vw solid #e4e4e4 !important;
        padding: 2.9375vw 1.125vw !important;
    }

    .substance .one-box .item-box .item img {
        width: 5.8vw !important;
        margin-bottom: -5.375vw !important;
    }

    .substance .one-box .item-box .item .title {
        font-size: 3vw !important;
        line-height: 1.5vw !important;
        padding-top: 8.4375vw !important;
    }

    .substance .one-box .item-box .item .desc {
        font-size: 3vw !important;
        /*line-height: 1.5vw!important;*/
        padding-top: 2.625vw !important;
        text-align: center;
    }

    #pro1 .process-title {
        width: 18vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .consulting-con .process-item:not(:nth-child(2)) {
        /*width: 32vw !important;*/
        overflow: hidden;
        /*text-overflow: ellipsis;*/
        /*white-space: nowrap;*/
    }

    .consulting-con .custom-div {
        width: 100vw;
        margin: 0 !important;
        /*margin-left: -21vw;*/
        background: #f7f8fa;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .consulting-con .custom-div .process .process-text {
        /*display: flex;*/
        /*flex-direction: column!important;*/
    }

    .consulting-con .custom-div .process .process-text .process-item:nth-child(even) {
        /*transform: rotate(90deg);*/
        /*height: 25vw;*/
        /*display: flex;*/
        /*justify-content: center;*/
    }

    .consulting-con .process-text {
        align-items: center;
        text-align: left;
        display: flex;
        flex-wrap: nowrap; /* Prevent items from wrapping */
        justify-content: space-around;
        width: 65vw !important;
        margin: auto;
        overflow: auto; /* Enable scrolling if content overflows */
    }

    .consulting-con .process-text .process-item {
        /*flex: 0 0 auto; !* Allow items to grow and shrink, but not basis *!*/
        /*max-width: 30%; !* Adjust as needed *!*/
        display: flex;
        flex-direction: column;
    }

    #item-text2 {
        display: block;
    }

    .consulting-con .process-text .process-item .item-text {
        display: none;
    }

    .consulting-con .custom-div .process .process-text .process-item:nth-child(odd) {
        /*display: flex;*/
        /*flex-direction: column;*/
        /*width: 100vw!important;*/
    }

    .consulting-con .custom-div .process .process-text .process-item:nth-child(odd) img {
        width: 5.8vw;
    }

    .consulting-con .custom-div .process .process-text .process-item:nth-child(even) img {
        width: 5.8vw !important;
    }

    .consulting-con .foot-content {
        padding: 110px 0;
        width: 100vw;
        min-width: 100vw;
    }

    .consulting-con .foot-title {
        padding-bottom: 0;
        font-size: 4vw;
        font-weight: 500;
        color: #a40031;
        text-align: center;
        margin-bottom: 3vw;
    }

    .consulting-con .foot-content {
        padding: 0;
        width: 100vw;
        min-width: 100vw;
        margin-top: 3vw;
    }

    .consulting-con .item {
        width: 20vw;
        border: 0.1vw solid #e4e4e4;
        padding: 1vw 1.8vw;
        flex-direction: column;
        display: flex;
        align-items: center;
    }

    .consulting-con .item-foot {
        margin-bottom: 5vw;
    }

    .consulting-con .process-text {
        width: 100vw !important;
    }

    .consulting-con .item-box {
        max-width: 91vw !important;
    }

    .consulting-con .substance-box1 .process .process-title, .consulting-con .substance-box2 .custom-div .process-title {
        padding-bottom: 0;
        font-size: 4vw;
        font-weight: 500;
        color: #a40031;
        text-align: center;
        margin-bottom: 3vw;
    }

    .substance .one-box .item-box .item .desc {
        display: none;
    }

    #desc2 {
        display: block;
    }

    #item2-box {
        /*display: flex!important;*/
        /*flex-direction: column;*/
        /*justify-content: center;*/
        /*align-items: center;*/
        display: flex !important;
        flex-wrap: wrap; /* Allow flex items to wrap onto multiple lines */
    }
    #item2-box .item {
        width: 30vw!important;
        padding-left: 3vw!important;
        padding-right: 3vw!important;
    }
    .substance .substance-box1 .item-box .item .desc, .substance .substance-box2 .item-box .item .desc{
        display: none;
    }
    #title1{
        display: none;
    }
    #title2{
        display: block;
    }
    .consulting-con .substance-box1 .item-box {
        width: 100vw !important;
        max-width: 100vw !important;
    }
}
