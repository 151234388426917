.main {
    flex: 1;

    .news-page {
        width: 1100px;
        margin: auto;
        min-width: 1100px;

        .b-title-left {
            font-size: 40px;
            font-weight: 500;
            color: #a40031;
            margin: 74px 0;
            margin-top: 30px;
        }

        .one-item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .news-thumb {
                    width: 613px;
                    height: 375px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                }

                .ant-col {
                    width: 450px;
                    position: relative;
                    min-height: 1px;

                    .b-time {
                        font-size: 16px;
                        font-weight: 400;
                        color: #999;
                        line-height: 45px;
                        a{
                            color: #a40031;
                            outline: none;
                            text-decoration: none;
                            -webkit-backface-visibility: hidden;
                        }
                    }

                    .b-sub-title {
                        margin-bottom: 43px;
                        font-size: 30px;
                        font-weight: 500;
                        color: #3e454d;
                        line-height: 45px;
                        margin-bottom: 18px;
                        height: 90px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                    }
                    .b-desc{
                        margin-bottom: 45px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #686865;
                        line-height: 24px;
                        margin-bottom: 18px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 4;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                    }
                    .b-detail{
                        a{
                            font-size: 16px;
                            color: #a40031;
                            outline: none;
                            text-decoration: none;
                            -webkit-backface-visibility: hidden;
                        }
                    }
                }
            }
        }
    }
}
