.SaaS-container {
    width: 100%;
    height: 100%;
    margin-bottom: 5vw;
}

.saas-top-con {
    width: 100%;
    height: 450px;
    background: url("../../../public/saas-banner.png") no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.saas-top-text {
    color: #fff;
    font-size: 42px;
    font-weight: 400;
    margin-bottom: 32px;
}

.saas-top-text2 {
    font-size: 17px;
    font-weight: 400;
    color: #FFFFFFB2;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    line-height: 24px;
    margin-bottom: 63px;
    color: hsla(0, 0%, 100%, .7);
}

.saas-top-btn {
    background: #a40031;
    width: 180px;
    height: 36px;
    color: #fff;
    cursor: pointer;
    border: 0;
    border-radius: 2px;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.saas-box {
    width: 1100px;
    height: 449px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    &:first-child {
        margin-top: 107px;
    }

    .saas-box-left {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .saas-box-left-text {
            font-size: 42px;
            font-weight: 400;
            color: #3e454d;
            margin-bottom: 32px;
            line-height: 52px;
            text-align: center;
        }

        span:first-child {
            font-size: 30px;
            color: #a40031;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 23px;
        }

        span:nth-child(2) {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #3e454d;
        }
    }

    .saas-box-right {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /*img{*/
        /*    width: 459px;*/
        /*    height: 449px;*/
        /*}*/
    }
}

@keyframes slideFromTop {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideFromBottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.saas-top-text, .saas-top-text2, .saas-top-btn, .saas-box-left, .saas-box-right {
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.saas-top-text {
    animation-name: slideFromTop;
}

.saas-top-text2 {
    animation-name: slideFromTop;
    animation-delay: 0.1s; /* Delay the animation */
}

.saas-top-btn {
    animation-name: slideFromBottom;
    animation-delay: 0.1s; /* Delay the animation */
}

.saas-box-left {
    animation-name: slideFromLeft;
}

.saas-box-right {
    animation-name: slideFromRight;
}

@media (max-width: 1651px) and (orientation: portrait) {
    .SaaS-container {
        width: 100vw;
    }
    .saas-top-con {
        width: 100%;
        height: 65vw;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .saas-top-text {
        font-size: 5.875vw;
    }
    .saas-top-text2 {
        font-size: 2.5vw;
        font-weight: 400;
        color: #FFFFFFB2;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        margin-bottom: 3.9375vw;
        color: hsla(0, 0%, 100%, .7);
    }
    .saas-top-btn {
        background: #a40031;
        width: 18.25vw;
        height: 4vw;
        color: #fff;
        cursor: pointer;
        border: 0;
        border-radius: 2px;
        text-align: center;
        line-height: 4vw;
        font-size: 1.875vw;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    }
    .saas-box {
        width: 95vw;
        height: 28.5156vw;
        display: flex;
        padding-left: 5vw;
        justify-content: space-between;
        align-items: center;
        margin: 6.577vw auto 0!important;
    }
    .saas-box .saas-box-left span:first-child {
        font-size: 3vw;
        color: #a40031;
        font-weight: 500;
        margin-bottom: 1.422vw;
    }
    .saas-box .saas-box-left span:nth-child(2) {
        font-size: 2vw;
        font-weight: 400;
        color: #3e454d;
    }
    .saas-box .saas-box-right img {
        width: 28.5156vw!important;
        height: 27.9375vw!important;
    }
}

