.solutions-con{
    width: 100%;
    height: 100%;
    margin-bottom: 140px;
    .solutions-top-con{
        width: 100%;
        height: 450px;
        background: url("../../../public/solution-banner.png")
        no-repeat
        center
        center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .solutions-top-text{
        color: #fff;
        font-size: 42px;
        font-weight: 400;
        margin-bottom: 32px;
    }
    .solutions-box{
        width: 1100px;
        margin: auto;
        min-width: 1100px;
        .solutions-tittle{
            width: 100%;
            height: 272px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .tittle{
                font-size: 40px;
                color: rgb(164, 0, 49);
                font-weight: 500;
                margin-bottom: 25px;
                text-align: center;
            }
            .substance{
                font-size: 17px;
                color: rgb(104, 104, 101);
                font-weight: 400;
                text-align: center;
            }
        }
        .item-box{
            width: 1100px;
            height: 317px;
            display: flex;
            .item{
                width: 25%;
                height: 100%;
                position: relative;
                padding: 30px 50px;
                min-height: 1px;
                background: #d02726;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .tittle{
                    font-size: 24px;
                    color: rgb(255, 255, 255);
                    font-weight: 400;
                    margin-bottom: 25px;
                    text-align: center;
                }
                .con{
                    width: 100%;
                    font-size: 18px;
                    font-weight: 400;
                    color: rgb(255, 255, 255);
                    line-height: 27px;
                    margin-bottom: 25px;
                    text-align: center;
                }
                .img{
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    z-index: 3;
                    transform: translate(50%, -50%);
                }
                .img-d{
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    right: 0px;
                    left: 50%;
                    bottom: 0px;
                    transform: translate(-50%, 50%) rotate(90deg);
                    z-index: 3;
                }
                .left-img{
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    z-index: 3;
                    transform: translate(50%, -50%) rotate(180deg);
                }
            }
            .item:nth-child(even) {
                background-color: #d02726; /* Set the deeper color */
            }

            .item:nth-child(odd) {
                background-color: #ff0000; /* Set your base color */
            }
        }
        .item-box2{
            width: 1100px;
            height: 317px;
            display: flex;
            .item2{
                width: 25%;
                height: 100%;
                position: relative;
                padding: 30px 50px;
                min-height: 1px;
                background: #d02726;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .tittle{
                    font-size: 24px;
                    color: rgb(255, 255, 255);
                    font-weight: 400;
                    margin-bottom: 25px;
                    text-align: center;
                }
                .con{
                    width: 100%;
                    font-size: 18px;
                    font-weight: 400;
                    color: rgb(255, 255, 255);
                    line-height: 27px;
                    margin-bottom: 25px;
                    text-align: center;
                }
                .img{
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    z-index: 3;
                    transform: translate(50%, -50%);
                }
                .img-d{
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    right: 0px;
                    left: 50%;
                    bottom: 0px;
                    transform: translate(-50%, 50%) rotate(90deg);
                    z-index: 3;
                }
                .left-img{
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    z-index: 3;
                    transform: translate(50%, -50%) rotate(180deg);
                }
            }
            .item2:nth-child(even) {
                background-color: #ff0000;
            }

            .item2:nth-child(odd) {
                background-color: #d02726;

            }
            .item3{
                width: 25%;
                height: 100%;
                position: relative;
                padding: 30px 50px;
                min-height: 1px;
                background: #d02726;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .tittle{
                    font-size: 24px;
                    color: rgb(255, 255, 255);
                    font-weight: 400;
                    margin-bottom: 25px;
                    text-align: center;
                }
                .con{
                    width: 100%;
                    font-size: 18px;
                    font-weight: 400;
                    color: rgb(255, 255, 255);
                    line-height: 27px;
                    margin-bottom: 25px;
                    text-align: center;
                }
                .img{
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    z-index: 3;
                    transform: translate(50%, -50%);
                }
                .img-d{
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    right: 0px;
                    left: 50%;
                    bottom: 0px;
                    transform: translate(-50%, 50%) rotate(90deg);
                    z-index: 3;
                }
                .left-img{
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    z-index: 3;
                    transform: translate(50%, -50%) rotate(180deg);
                }
            }
            .item3:nth-child(even) {
                background: rgb(45, 90, 255);
            }

            .item3:nth-child(odd) {
                background: rgb(70, 110, 255);

            }
            .item4{
                width: 25%;
                height: 100%;
                position: relative;
                padding: 30px 50px;
                min-height: 1px;
                background: #d02726;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .tittle{
                    font-size: 24px;
                    color: rgb(255, 255, 255);
                    font-weight: 400;
                    margin-bottom: 25px;
                    text-align: center;
                }
                .con{
                    width: 100%;
                    font-size: 18px;
                    font-weight: 400;
                    color: rgb(255, 255, 255);
                    line-height: 27px;
                    margin-bottom: 25px;
                    text-align: center;
                }
                .img{
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    z-index: 3;
                    transform: translate(50%, -50%);
                }
                .img-d{
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    right: 0px;
                    left: 50%;
                    bottom: 0px;
                    transform: translate(-50%, 50%) rotate(90deg);
                    z-index: 3;
                }
                .left-img{
                    width: 40px;
                    height: 40px;
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    z-index: 3;
                    transform: translate(50%, -50%) rotate(180deg);
                }
            }
            .item4:nth-child(even) {
                background: rgb(70, 110, 255);
            }

            .item4:nth-child(odd) {
                background: rgb(45, 90, 255);
            }
        }
    }
    .solutions-box2{
        width: 100%;
        padding: 98px 0;
        background: rgb(247, 248, 250);
        display: flex;
        flex-direction: column;
        align-items: center;
        .solutions-text{
            font-size: 40px;
            color: rgb(164, 0, 49);
            font-weight: 500;
            margin-bottom: 25px;
            text-align: center;
        }
        .solutions-text2{
            margin-bottom: 72px;
            font-size: 17px;
            color: rgb(104, 104, 101);
            font-weight: 400;
            text-align: center;
        }
        .item-one{
            width: 300px;
            height: 180px;
            position: relative;
            min-height: 1px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .item-one2{
            width: 20%;
            height: 180px;
            position: relative;
            min-height: 1px;
            color: rgb(255, 255, 255);
            text-align: center;
            line-height: 180px;
        }
        .item-one span {
            font-size: 18px;
            color: rgb(131, 137, 146);
            font-weight: 400;
            margin-bottom: 18px;
            text-align: center;
        }
        .item-box {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .item {
            width: 80%;
            display: flex;
            align-items: center;
            margin: 0 auto 20px;
        }
        .item:nth-child(2) {
            background: url("../../../public/solution-sub.png") repeat;
            background-size: 100% 100%;
        }
    }
    .solutions-box3{
        width: 100%;
        padding: 98px 0;
        background: rgb(247, 248, 250);
        display: flex;
        flex-direction: column;
        align-items: center;
        .solutions-text{
            font-size: 40px;
            color: rgb(164, 0, 49);
            font-weight: 500;
            margin-bottom: 25px;
            text-align: center;
        }
        .solutions-tittle{
            width: 100%;
            height: 272px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .tittle{
                font-size: 40px;
                color: rgb(164, 0, 49);
                font-weight: 500;
                margin-bottom: 25px;
                text-align: center;
            }
            .substance{
                font-size: 17px;
                color: rgb(104, 104, 101);
                font-weight: 400;
                text-align: center;
            }
        }
        .item{
            width: 1100px;
            height: 694px;
            display: flex;
            justify-content: space-between;
            .one-item{
                width: 25%;
                text-align: left;
                .one-top{
                    margin-bottom: 180px;
                    display: flex;
                    flex-direction: column;
                    .one-title{
                        color: rgb(75, 173, 234);
                        font-size: 24px;
                        font-weight: 500;
                        margin-bottom: 18px;
                    }
                    .one-text{
                        font-size: 16px;
                        font-weight: 400;
                        color: rgb(131, 137, 146);
                        margin-bottom: 17px;
                    }
                    .one-title2{
                        color: rgb(60, 185, 198);
                        font-size: 24px;
                        font-weight: 500;
                        margin-bottom: 18px;
                    }
                    .one-title3{
                        color: rgb(232, 107, 45);;
                        font-size: 24px;
                        font-weight: 500;
                        margin-bottom: 18px;
                    }
                    .one-title4{
                        color: rgb(255, 168, 64);;
                        font-size: 24px;
                        font-weight: 500;
                        margin-bottom: 18px;
                    }
                }
            }
            .two-item{
                width: 41%;
                .img{
                    width: 100%;
                    vertical-align: middle;
                    border-style: none;
                }
            }
        }
    }
}

.solutions-con {
    width: 100%;
    height: 100%;
}

.solutions-con .solutions-top-con {
    width: 100%;
    height: 450px;
    background: url("../../../public/solution-banner.png") no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.solutions-con .solutions-top-text {
    color: #fff;
    font-size: 42px;
    font-weight: 400;
    margin-bottom: 32px;
}

.solutions-con .solutions-box {
    width: 1100px;
    margin: auto;
    min-width: 1100px;
}

.solutions-con .solutions-box .solutions-tittle {
    width: 100%;
    height: 272px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.solutions-con .solutions-box .solutions-tittle .tittle {
    font-size: 40px;
    color: rgb(164, 0, 49);
    font-weight: 500;
    margin-bottom: 25px;
    text-align: center;
}

.solutions-con .solutions-box .solutions-tittle .substance {
    font-size: 17px;
    color: rgb(104, 104, 101);
    font-weight: 400;
    text-align: center;
}

.solutions-con .solutions-box .item-box {
    width: 1100px;
    height: 317px;
    display: flex;
}

.solutions-con .solutions-box .item {
    width: 25%;
    height: 100%;
    position: relative;
    padding: 30px 50px;
    min-height: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.solutions-con .solutions-box .item .tittle {
    font-size: 24px;
    color: rgb(255, 255, 255);
    font-weight: 400;
    margin-bottom: 25px;
    text-align: center;
}

.solutions-con .solutions-box .item .con {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    line-height: 27px;
    margin-bottom: 25px;
    text-align: center;
}

.solutions-con .solutions-box .item .img {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0px;
    top: 50%;
    z-index: 3;
    transform: translate(50%, -50%);
}

.solutions-con .solutions-box .item .img-d {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0px;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, 50%) rotate(90deg);
    z-index: 3;
}

.solutions-con .solutions-box .item .left-img {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0px;
    top: 50%;
    z-index: 3;
    transform: translate(50%, -50%) rotate(180deg);
}

.solutions-con .solutions-box .item:nth-child(even) {
    background-color: #d02726;
}

.solutions-con .solutions-box .item:nth-child(odd) {
    background-color: #ff0000;
}

#item2 .item:nth-child(even) {
    background-color: #1b9de8!important;
}

#item2 .item:nth-child(odd) {
    background-color: #0a5a8a !important;
}

#item2 .item2:nth-child(even) {
    background-color: #0a5a8a !important;
}

#item2 .item2:nth-child(odd) {
    background-color: #1b9de8!important;
}

.solutions-con .solutions-box .item-box2,
.solutions-con .solutions-box .item-box3 {
    width: 1100px;
    height: 317px;
    display: flex;
}

.solutions-con .solutions-box .item-box2 .item2,
.solutions-con .solutions-box .item-box3 .item3,
.solutions-con .solutions-box .item-box3 .item4 {
    width: 25%;
    height: 100%;
    position: relative;
    padding: 30px 50px;
    min-height: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.solutions-con .solutions-box .item-box2 .item2 .tittle,
.solutions-con .solutions-box .item-box3 .item3 .tittle,
.solutions-con .solutions-box .item-box3 .item4 .tittle {
    font-size: 24px;
    color: rgb(255, 255, 255);
    font-weight: 400;
    margin-bottom: 25px;
    text-align: center;
}

.solutions-con .solutions-box .item-box2 .item2 .con,
.solutions-con .solutions-box .item-box3 .item3 .con,
.solutions-con .solutions-box .item-box3 .item4 .con {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    line-height: 27px;
    margin-bottom: 25px;
    text-align: center;
}

.solutions-con .solutions-box .item-box2 .item2 .img,
.solutions-con .solutions-box .item-box2 .item2 .img-d,
.solutions-con .solutions-box .item-box2 .item2 .left-img,
.solutions-con .solutions-box .item-box3 .item3 .img,
.solutions-con .solutions-box .item-box3 .item3 .img-d,
.solutions-con .solutions-box .item-box3 .item3 .left-img,
.solutions-con .solutions-box .item-box3 .item4 .img,
.solutions-con .solutions-box .item-box3 .item4 .img-d,
.solutions-con .solutions-box .item-box3 .item4 .left-img {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0px;
    top: 50%;
    z-index: 3;
}

.solutions-con .solutions-box .item-box2 .item2 .img {
    transform: translate(50%, -50%);
}

.solutions-con .solutions-box .item-box2 .item2 .img-d {
    right: 0px;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, 50%) rotate(90deg);
}

.solutions-con .solutions-box .item-box2 .item2 .left-img {
    transform: translate(50%, -50%) rotate(180deg);
}

.solutions-con .solutions-box .item-box2 .item2:nth-child(even),
.solutions-con .solutions-box .item-box3 .item3:nth-child(even),
.solutions-con .solutions-box .item-box3 .item4:nth-child(even) {
    background-color: #ff0000;
}

.solutions-con .solutions-box .item-box2 .item2:nth-child(odd),
.solutions-con .solutions-box .item-box3 .item3:nth-child(odd),
.solutions-con .solutions-box .item-box3 .item4:nth-child(odd) {
    background-color: #d02726;
}

.solutions-con .solutions-box2 {
    width: 100%;
    padding: 98px 0;
    background: rgb(247, 248, 250);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.solutions-con .solutions-box2 .solutions-text {
    font-size: 40px;
    color: rgb(164, 0, 49);
    font-weight: 500;
    margin-bottom: 25px;
    text-align: center;
}

.solutions-con .solutions-box2 .solutions-text2 {
    margin-bottom: 72px;
    font-size: 17px;
    color: rgb(104, 104, 101);
    font-weight: 400;
    text-align: center;
}

.solutions-con .solutions-box2 .item-one {
    width: 300px;
    height: 180px;
    position: relative;
    min-height: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.solutions-con .solutions-box2 .item-one2 {
    width: 20%;
    height: 180px;
    position: relative;
    min-height: 1px;
    color: rgb(255, 255, 255);
    text-align: center;
    line-height: 180px;
}

.solutions-con .solutions-box2 .item-one span {
    font-size: 18px;
    color: rgb(131, 137, 146);
    font-weight: 400;
    margin-bottom: 18px;
    text-align: center;
}

.solutions-con .solutions-box2 .item-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.solutions-con .solutions-box2 .item {
    width: 80%;
    display: flex;
    align-items: center;
    margin: 0 auto 20px;
}

.solutions-con .solutions-box2 .item:nth-child(2) {
    background: url("../../../public/solution-sub.png") repeat;
    background-size: 100% 100%;
}

.solutions-con .solutions-box3 {
    width: 1903px;
    padding: 98px 0;
    background: rgb(247, 248, 250);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.solutions-con .solutions-box3 .solutions-text {
    font-size: 40px;
    color: rgb(164, 0, 49);
    font-weight: 500;
    margin-bottom: 25px;
    text-align: center;
}

.solutions-con .solutions-box3 .solutions-tittle {
    width: 100%;
    height: 272px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.solutions-con .solutions-box3 .solutions-tittle .tittle {
    font-size: 40px;
    color: rgb(164, 0, 49);
    font-weight: 500;
    margin-bottom: 25px;
    text-align: center;
}

.solutions-con .solutions-box3 .solutions-tittle .substance {
    font-size: 17px;
    color: rgb(104, 104, 101);
    font-weight: 400;
    text-align: center;
}

.solutions-con .solutions-box3 .item {
    width: 1100px;
    height: 694px;
    display: flex;
    justify-content: space-between;
}

.solutions-con .solutions-box3 .item .one-item {
    width: 25%;
    text-align: left;
}

.solutions-con .solutions-box3 .item .one-item .one-top {
    margin-bottom: 180px;
    display: flex;
    flex-direction: column;
}

.solutions-con .solutions-box3 .item .one-item .one-top .one-title {
    color: rgb(75, 173, 234);
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 18px;
}

.solutions-con .solutions-box3 .item .one-item .one-top .one-text {
    font-size: 16px;
    font-weight: 400;
    color: rgb(131, 137, 146);
    margin-bottom: 17px;
}

.solutions-con .solutions-box3 .item .one-item .one-top .one-title2 {
    color: rgb(60, 185, 198);
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 18px;
}

.solutions-con .solutions-box3 .item .one-item .one-top .one-title3 {
    color: rgb(232, 107, 45);;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 18px;
}

.solutions-con .solutions-box3 .item .one-item .one-top .one-title4 {
    color: rgb(255, 168, 64);;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 18px;
}

.solutions-con .solutions-box3 .item .two-item {
    width: 41%;
}

.solutions-con .solutions-box3 .item .two-item .img {
    width: 100%;
    vertical-align: middle;
    border-style: none;
}


@keyframes slideFromTop {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideFromBottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.solutions-top-text,
.small-content {
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.solutions-top-text {
    animation-name: slideFromTop;
}

.small-content {
    animation-name: slideFromBottom;
}
@media (max-width: 1651px) and (orientation: portrait) {
    .solutions-con {
        margin-bottom: 10px!important;
    }
    .solutions-top-con {
        width: 100%;
        height: 65vw !important;
    }

    .solutions-top-con .solutions-top-text {
        font-size: 5.875vw !important;
    }

    .solutions-box3 {
        width: 100vw !important;
        padding: 6.125vw 0 !important;
        background: rgb(247, 248, 250) !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .solutions-box3 .solutions-tittle {
        width: 100%;
        height: 16.9375vw;
        padding-top: 6.125vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .solutions-box3 .solutions-tittle .tittle {
        font-size: 5.5vw !important;
        color: rgb(164, 0, 49) !important;
        font-weight: 500 !important;
        line-height: 1.5 !important;
        margin-bottom: 6vw !important;
        text-align: center !important;
    }

    .solutions-box3 .solutions-tittle .substance {
        font-size: 1.0625vw;
        color: rgb(104, 104, 101);
        font-weight: 400;
        line-height: 1.5;
        text-align: center;
    }

    .solutions-box3 .item {
        width: 68.75vw !important;
        height: 43.375vw !important;
        display: flex;
        justify-content: space-between;
    }

    .solutions-box3 .item .one-top {
        margin-bottom: 11.25vw !important;
        display: flex !important;
        flex-direction: column !important;
    }

    .solutions-box3 .item .one-top .one-title {
        color: rgb(75, 173, 234) !important;
        font-size: 4.5vw !important;
        font-weight: 500 !important;
        margin-bottom: 1.125vw !important;
        width: 17vw !important;
    }

    .solutions-box3 .item .one-top .one-text {
        font-size: 1vw !important;
        font-weight: 400 !important;
        color: rgb(131, 137, 146) !important;
        line-height: 1.5 !important;
        margin-bottom: 1.0625vw !important;
        display: none;
    }

    .solutions-box3 .item .one-top .one-title2,
    .solutions-box3 .item .one-top .one-title4 {
        font-size: 4.5vw !important;
    }

    .solutions-box3 .one-title3 {
        color: rgb(232, 107, 45);
        font-size: 4.5vw !important;
        font-weight: 500 !important;
        margin-bottom: 1.125vw !important;
        width: 17vw !important;
    }

    .solutions-box {
        width: 68.75vw !important;
        margin: auto;
        min-width: 68.75vw !important;
        padding-bottom: 10vw;
    }

    .solutions-box .solutions-tittle {
        width: 100%;
        height: 32vw !important;
        padding-top: 0 !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .solutions-box .solutions-tittle .tittle {
        font-size: 5.5vw !important;
        color: rgb(164, 0, 49) !important;
        font-weight: 500 !important;
        line-height: 2.5vw !important;
        text-align: center !important;
    }

    .solutions-box .solutions-tittle .substance {
        font-size: 3vw !important;
        color: rgb(104, 104, 101) !important;
        font-weight: 400 !important;
        text-align: center !important;
    }

    .solutions-box .item-box2,
    .solutions-box .item-box {
        width: 68.75vw !important;
        height: 18.40625vw !important;
        display: flex;
    }
    .solutions-box .item-box2 .item2,
    .solutions-box .item-box2 .item3,
    .solutions-box .item-box2 .item4,
    .solutions-box .item-box .item {
        width: 25% !important;
        height: 100% !important;
        position: relative !important;
        padding: 1vw 0vw !important;
        min-height: 1px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .solutions-box .item-box2 .item3 .tittle,
    .solutions-box .item-box2 .item4 .tittle,
    .solutions-box .item-box .item .tittle {
        font-size: 3vw !important;
        color: #fff;
        font-weight: 400;
        line-height: 1.5vw !important;
        margin-bottom: 3.125vw !important;
        text-align: center;
    }
    .solutions-box .item-box2 .item3 .con,
    .solutions-box .item-box2 .item4 .con,
    .solutions-box .item-box .item .con {
        width: 100% !important;
        font-size: 1.125vw !important;
        font-weight: 400 !important;
        color: rgb(255, 255, 255) !important;
        line-height: 1.5vw !important;
        margin-bottom: 1.5625vw !important;
        text-align: center !important;
        display: none;
    }

    .solutions-box .item-box .item .con {
        width: 100% !important;
        font-size: 1.125vw !important;
        font-weight: 400 !important;
        color: rgb(255, 255, 255) !important;
        line-height: 1.5vw !important;
        margin-bottom: 1.5625vw !important;
        text-align: center !important;
        display: none;
    }

    .solutions-box .item-box2 .item4 .tittle{
        font-size: 3vw !important;
        color: #fff;
        font-weight: 400;
        line-height: 1.5vw !important;
        margin-bottom: 3.125vw !important;
        text-align: center;

    }
    .solutions-box .item-box2 .item3 img,
    .solutions-box .item-box2 .item4 img,
    .solutions-box .item-box .item img {
        width: 2.5vw !important;
        height: 2.5vw !important;
        position: absolute !important;
        right: 0 !important;
        top: 50% !important;
        z-index: 3 !important;
        transform: translate(50%, -50%) !important;
    }
    .solutions-box .item-box2 .item3 .img-d,
    .solutions-box .item-box .item .img-d {
        width: 2.5vw !important;
        height: 2.5vw !important;
        position: absolute !important;
        right: 0 !important;
        left: 50% !important;
        bottom: 0 !important;
        transform: translate(-50%, 263%) rotate(90deg) !important;
        z-index: 3 !important;
    }

    .solutions-box .item-box2 {
        width: 68.75vw !important;
        height: 19.8125vw !important;
        display: flex;
    }

    .solutions-box .item-box2 .item2 {
        width: 25% !important;
        height: 100% !important;
        position: relative !important;
        padding: 0 !important;
        min-height: 1px !important;
        background: #d02726;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .solutions-box .item-box2 .item2 .tittle {
        font-size: 3vw !important;
        color: rgb(255, 255, 255) !important;
        font-weight: 400;
        line-height: 1.5vw !important;
        margin-bottom: 1.5625vw !important;
        text-align: center;
    }

    .solutions-box .item-box2 .item2 .con {
        width: 100% !important;
        font-size: 1.125vw !important;
        font-weight: 400 !important;
        color: rgb(255, 255, 255) !important;
        line-height: 1.6875vw !important;
        margin-bottom: 1.5625vw !important;
        text-align: center !important;
        display: none;
    }

    .solutions-box .item-box2 .item2 .left-img {
        width: 2.5vw !important;
        height: 2.5vw !important;
        position: absolute;
        right: 0;
        top: 50%;
        z-index: 3;
        transform: translate(50%, -50%) rotate(180deg);
    }

    .solutions-box2 {
        width: 100vw !important;
        background: rgb(247, 248, 250) !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .solutions-box2 .solutions-text {
        font-size: 5.5vw !important;
        color: rgb(164, 0, 49) !important;
        font-weight: 500 !important;
        line-height: 1.5vw !important;
        margin-bottom: 4.5625vw !important;
        text-align: center !important;
    }

    .solutions-box2 .solutions-text2 {
        margin-bottom: 4.5vw !important;
        font-size: 3vw !important;
        color: rgb(104, 104, 101) !important;
        font-weight: 400 !important;
        text-align: center !important;
    }

    .solutions-box2 .item-box {
        width: 80vw;
    }

    .solutions-box2 .item-box .item {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0 auto 2.5vw;
    }

    .solutions-box2 .item-box .item .item-one {
        width: 18.75vw;
        height: 18.9375vw;
        position: relative;
        min-height: 1px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .solutions-box2 .item-box .item .item-one span {
        font-size: 2.5vw;
        color: rgb(131, 137, 146);
        font-weight: 400;
        margin-bottom: 1.125vw;
        text-align: center;
        width: 27vw;
    }

    .solutions-box2 .item-box .item .item-one2 {
        width: 20vw;
        height: 10.9375vw;
        position: relative;
        min-height: 1px;
        color: rgb(255, 255, 255);
        text-align: center;
        line-height: 10.9375vw;
        font-size: 2vw;
    }
    .solutions-con .solutions-box2 .item:nth-child(2) {
        height: 21vw;
    }
}

