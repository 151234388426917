/* Footer.css */
.footer {
    width: 100%;
    height: 344px;
    background-color: #2D353F;
    padding-top: 1px;
}

.wrap {
    width: 1200px;
    margin: auto;
    min-width: 1200px;
}

.img-footer {
    width: 100px;
    height: 36.83px;
    filter: brightness(0) invert(1);
}

.menu {
    display: flex;
    align-items: center;
}

.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.menu ul li {
    margin-right: 88px;
}

.menu ul li:last-child {
    margin-right: 0;
}

.menu ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-family: Arial, sans-serif;
    transition: color 0.3s;
}

.menu ul li a:hover {
    color: #d02726;
}

.footer-description {
    color: #fff;
    width: 1200px;
    height: 132px;
    margin-top: 31px;
    display: flex;
}

.footer-description-left {
    height: 132px;
}

.footer-description-right {
    display: flex;
    align-items: center;
}

.footer-left-item {
    margin-bottom: 20px;
}

.footer-left-item:hover {
    color: #d02726;
}

.footer-left-img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.email-input {
    width: 346px;
    height: 24px;
    padding: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    margin-right: 10px;
}

.apply-btn {
    width: 180px;
    height: 36px;
    background-color: #d02726;
    color: #fff;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.apply-btn:hover {
    background-color: #b02421;
}
.wrap {
    width: 1200px;
    margin: auto;
    min-width: 1200px;
}
.footer-code {
    color: #ffffff;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Arial, sans-serif;
    margin-bottom: 8px;
}

.footer-code span {
    font-size: 14px;
    font-family: Arial, sans-serif;
    margin-right: 6px;
}
.title{
    color: #222;
    font-size: 42px;
    font-family: Arial, sans-serif; /* 调整字体 */
    margin-bottom: 20px; /* 调整标题与描述之间的间距 */
}

@media (max-width: 1651px) and (orientation: portrait) {
    .footer {
        width: 100%;
        height: 35vw;
        background-color: #2D353F;
        padding-top: 0.14vw;
    }
    .footer .wrap {
        width: 100vw;
        margin: auto;
        min-width: 100vw;
    }
    .footer .wrap .title {
        color: #222;
        font-size: 2.55vw;
        font-family: Arial, sans-serif;
        margin-bottom: 1.07vw;
        margin-top: 2.86vw;
        margin-left: 3vw;
    }
    .footer .wrap .title .img-footer {
        width: 10.75vw;
        height: 5.92vw;
        filter: brightness(0) invert(1);
    }
    .footer .wrap .title .menu {
        white-space: nowrap;
        margin: 0 5% 0 5%;
        flex: 1 1;
        display: inline-flex;
        list-style-type: none;
        padding: 0;
    }
    .footer .wrap .title .menu ul li a {
        text-decoration: none;
        color: #fff;
        font-size: 2.42vw;
        font-family: Arial, sans-serif;
        transition: color 0.3s;
    }
    .footer .wrap .title .menu ul li {
        margin-right: 5vw;
    }
    .footer .wrap .footer-description {
        color: #fff;
        width: 100vw;
        height: 14vw;
        margin-top: 2vw;
        display: flex;
    }
    .footer .wrap .footer-description .footer-description-left {
        height: 15vw;
    }
    .footer .wrap .footer-description .footer-description-left .footer-left-item {
        margin-bottom: 1.5vw;
    }
    .footer .wrap .footer-description .footer-description-left .footer-left-item .footer-left-img {
        width: 2vw;
        height: 2vw;
        margin-right: 0.5vw;
    }
    .footer .wrap .footer-description .footer-description-left .footer-left-item span {
        font-size: 2.42vw;
    }
    .footer .wrap .footer-description .footer-description-right .email-input {
        width: 30vw;
        height: 0;
        padding: 1.5vw;
        border: 0.1vw solid #ccc;
        font-size: 1.2vw;
        margin-right: 0;
    }
    .footer .wrap .footer-description .footer-description-right .apply-btn {
        width: 23.4375vw;
        height: 3.75vw;
        background-color: #d02726;
        color: #fff;
        border: none;
        font-size: 1.2vw;
        cursor: pointer;
    }
    .footer .wrap .flex-align-center .footer-code {
        color: #ffffff;
        display: flex;
        align-items: center;
        font-size: 0.875vw;
        font-family: Arial, sans-serif;
        margin-bottom: 0.5vw;
    }
    .footer .wrap .flex-align-center .footer-code img {
        width: 1.5vw;
        height: 1.5vw;
        margin-right: 0.5vw;
    }
    .footer .wrap .flex-align-center .footer-code span {
        font-size: 0.875vw;
        font-family: Arial, sans-serif;
        margin-right: 0.35vw;
    }
    #address{
        display: flex;
        align-items: center;
        margin-left: 3vw;
    }
    #address img{
        margin-right: 1vw;
        margin-bottom: -0.5vw;
    }
    #email{
        display: none;
    }
}

