.header-con {
    width: 100%;
    height: 56px;
    position: sticky;
    z-index: 999;
    top: 0;
    display: flex;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    line-height: 56px;
}

.header {
    width: 1200px;
    margin: auto;
    min-width: 1200px;
    height: 56px;
    align-items: center;
}

.logo {
    width: 8.5%;
    height: auto;
    margin-right: 4.5%;
}

.menu {
    display: inline-flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex: 1;
}
a {
    font-size: 16px;
}
.menu-item {
    display: inline-block;
    margin-right: 3%; /* 使用百分比单位自适应菜单项之间的间距 */
    color: #000000a6;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap; /* 阻止文本换行 */
}

.menu-item:hover {
    color: #d02726;
}

.active {
    color: #d02726;
}

.buttons {
    display: flex;
    align-items: center;
}

.login-btn,
.free-btn {
    margin-left: 2%; /* 使用百分比单位自适应按钮之间的间距 */
    font-size: 16px;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.free-btn {
    width: 180px;
    white-space: nowrap;
    height: 36px;
    background: #d02726;
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    margin-left: 30px;
    line-height: 36px;

    &:hover {
        background: #c03633;
        color: #fff;
    }
}

.menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*width: 100%;*/
    width: 1200px;
    margin: 0 auto;
}

.menu-logo {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 20px;
}

@media (max-width: 1174px) {
    .buttons {
        /*display: none;*/
    }

    .free-btn {
        width: 100%;
        margin-left: 7px;
        padding: 0 7px;
        font-size: 12px;
    }

    .login-btn {
        font-size: 12px;
    }

    .menu-item {
        margin-right: 5%;
    }

    .logo {
        margin-right: 3%;
    }
}

.menu-collapse {
    display: none;
}

.menu-expanded {
    display: block;
}


.header {
    margin: auto;
    min-width: 200px;
    height: 56px;
    align-items: center;
}

.menu-logo, .buttons {
    display: flex; /* 放置在共同的父元素内 */
    align-items: center;
}

#buttons{
    margin-left: -20vw;
}

.menu-container {
    margin: 0;
}

.logo {
    width: 100px;
    margin: 5px 0 0 0;
    height: auto;
}

.header {
    height: 56px;
    position: sticky;
    z-index: 999;
    top: 0;
    display: flex;
    align-items: center;
    padding: 0 1%;
}

.menu {
    width: 250px;
    height: 100%;
    /*overflow-x: auto;*/
    white-space: nowrap;
    /*margin: 0 5% 0 5%;*/
    margin: 0 5% 0 5%;
    flex: 1; /* 占据剩余空间 */
    display: inline-flex;
    list-style-type: none;
    padding: 0;
}

.menu-item {
    margin-right: 40px;
    font-size: 12px;
    display: inline-block;
    color: #000000a6;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
}
@media (max-width: 1651px) and (orientation: portrait) {
    a {
        font-size: 3vw!important;
    }
    .menu-item:not(:last-child) {
        margin-right: 2vw!important;
    }
    .logo {
        width: 11vw;
        margin: 5px 0 5px 4vw;
        height: auto;
    }
    .menu{
        margin-right: 0!important;
    }
}
