.about-con{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    .top-title{
        width: 100%;
        height: 450px;
        background: url("../../../public/about-banner.png")
        no-repeat
        center
        center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .tittle{
            font-size: 42px;
            font-weight: 400;
            color: rgb(255, 255, 255);
            width: 100%;
            text-align: center;
        }
    }
    .small-wrap{
        position: relative;
        padding: 100px 0 260px;
        width: 1100px;
        margin: auto;
        min-width: 1100px;
        .about-text{
            width: 50%;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 19px 1px;
            padding: 76px 88px;
            text-align: left;
            position: relative;
            z-index: 1;
            background: rgb(255, 255, 255);
            .about-title{
                font-size: 26px;
                color: rgb(34, 34, 34);
                font-weight: 400;
                margin-bottom: 32px;
            }
            .about-desc{
                font-size: 16px;
                color: rgb(131, 131, 131);
                font-weight: 500;
                line-height: 24px;
            }
        }
        .about-bg{
            text-align: right;
            position: absolute;
            z-index: 0;
            transform: translateY(-200px);
            img{
                width: 60%;
            }
        }
    }
}

.about-con {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
}

.top-title {
    width: 100%;
    height: 450px;
    background: url("../../../public/about-banner.png") no-repeat center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.top-title .tittle {
    font-size: 42px;
    font-weight: 400;
    color: rgb(255, 255, 255);
    width: 100%;
    text-align: center;
}

.small-wrap {
    position: relative;
    padding: 100px 0 260px;
    width: 1100px;
    margin: auto;
    min-width: 1100px;
}

.about-text {
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 19px 1px;
    padding: 76px 88px;
    text-align: left;
    position: relative;
    z-index: 1;
    background: rgb(255, 255, 255);
}

.about-text .about-title {
    font-size: 26px;
    color: rgb(34, 34, 34);
    font-weight: 400;
    margin-bottom: 32px;
}

.about-text .about-desc {
    font-size: 16px;
    color: rgb(131, 131, 131);
    font-weight: 500;
    line-height: 24px;
}

.about-bg {
    text-align: right;
    position: absolute;
    z-index: 0;
    transform: translateY(-200px);
}

.about-bg img {
    width: 60%;
}


@keyframes slideInTop {
    0% {
        opacity: 0;
        transform: translateY(-50px); /* 初始位置 */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* 最终位置 */
    }
}

@keyframes slideInBottom {
    0% {
        opacity: 0;
        transform: translateY(50px); /* 初始位置 */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* 最终位置 */
    }
}

.tittle {
    animation: slideInTop 1s ease forwards; /* 使用slideInTop动画，持续1秒，缓动效果，动画结束后保持最终状态 */
}

.small-wrap {
    position: relative;
    padding: 100px 0 260px;
    width: 100vw;
    margin: auto;
    min-width: 1100px;
    animation: slideInBottom 1s ease forwards; /* 使用slideInBottom动画，持续1秒，缓动效果，动画结束后保持最终状态 */
}
@media (max-width: 1651px) and (orientation: portrait) {
    .about-con {
        width: 100vw!important;
        justify-content: flex-start!important;
        align-items: flex-start!important;
        margin-bottom: 50vw;
        height: 56vh!important;
    }

    .about-con .top-title {
        height: 65vw !important;
    }

    .about-con .top-title .tittle {
        font-size: 5.875vw !important;
    }

    .about-con .small-wrap {
        margin: 0!important;
    }

    .about-con .small-wrap .about-text {
        width: 72.6667vw!important;
        padding: 6.3333vw 7.3333vw!important;
        margin: 5vw 0;
    }

    .about-con .small-wrap .about-text .about-title {
        font-size: 3vw!important;
        margin-bottom: 2.6667vw!important;
    }

    .about-con .small-wrap .about-text .about-desc {
        font-size: 3vw!important;
    }

    .about-con .small-wrap .about-bg {
        transform: translate(-3vw, 35vw)!important;
    }

    .about-con .small-wrap .about-bg img {
        width: 60vw;
        margin-right: -39vw!important;
    }
}
