/* 去除链接样式 */
a {
    text-decoration: none;
    color: inherit;
}

/* 去除按钮样式 */
button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: none;
}

/* 去除列表样式 */
ul, ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* 去除段落样式 */
p {
    margin: 0;
    padding: 0;
}

/* 去除标题样式 */
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

/* 去除文本框样式 */
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
    border: 1px solid #ccc;
    padding: 5px;
    margin: 0;
    font: inherit;
}

/* 去除下划线 */
u {
    text-decoration: none;
}
.App{
    width: 100%;
    /*overflow-x: hidden;*/
}
html, body {
    overflow-x: hidden;
}