.saasCom {
    width: 100%;
    background-color: #fff;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0; /* 初始透明度为0 */
    animation: fadeIn 1s forwards; /* 使用动画fadeIn，持续1秒，动画结束后保持最终状态 */
}

.big-box .title,
.big-box .description,
.big-box .button-box {
    opacity: 0; /* 初始透明度为0 */
    animation: slideInLeft 1s forwards; /* 从左边滑入，持续1秒，动画结束后保持最终状态 */
}

.small-wrap .box {
    opacity: 0; /* 初始透明度为0 */
    animation: slideInUp 1s forwards; /* 从下方滑入，持续1秒，动画结束后保持最终状态 */
}


.right-box-img {
    opacity: 0; /* 初始透明度为0 */
    animation: slideInRight 1s forwards; /* 从右边滑入，持续1秒，动画结束后保持最终状态 */
}

@keyframes fadeIn {
    from {
        opacity: 0; /* 初始透明度为0 */
    }
    to {
        opacity: 1; /* 最终透明度为1 */
    }
}

@keyframes slideInLeft {
    from {
        transform: translateX(-100px); /* 从左边移出 */
        opacity: 0; /* 初始透明度为0 */
    }
    to {
        transform: translateX(0); /* 移动到原位置 */
        opacity: 1; /* 最终透明度为1 */
    }
}

@keyframes slideInUp {
    from {
        transform: translateY(100px); /* 从下方移出 */
        opacity: 0; /* 初始透明度为0 */
    }
    to {
        transform: translateY(0); /* 移动到原位置 */
        opacity: 1; /* 最终透明度为1 */
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(100px); /* 从右边移出 */
        opacity: 0; /* 初始透明度为0 */
    }
    to {
        transform: translateX(0); /* 移动到原位置 */
        opacity: 1; /* 最终透明度为1 */
    }
}

.big-box {
    width: 90%;
    max-width: 1200px; /* 设置最大宽度，防止过宽 */
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: space-between; /* 将左右盒子分开 */
}

.left-box {
    width: 50%; /* 左侧盒子占50%宽度 */
    height: 100%;
    /*background: #cccccc;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px; /* 添加内边距 */
}

.left-box .title {
    color: #222;
    font-size: 42px;
    font-family: Arial, sans-serif; /* 调整字体 */
    margin-bottom: 20px; /* 调整标题与描述之间的间距 */
}

.left-box .description {
    color: #686865;
    font-size: 17px;
    font-family: Arial, sans-serif; /* 调整字体 */
    margin-top: 48px;
    margin-bottom: 76px;
}

.right-box {
    width: 50%;
    height: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-box .right-box-img {
    width: 70%;
    height: 80%;
    max-width: 100%;
    max-height: 100%;
}

.buttons {
    display: flex;
    align-items: center;
}

.button-box .free-btn {
    width: 180px;
    white-space: nowrap;
    height: 36px;
    background: #d02726;
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    margin-left: 0;

    &:hover {
        background: #c03633;
        color: #fff;
    }
}

/*small-wrap big-box display-sb*/
.small-wrap {
    width: 1171px;
    margin: auto;
    min-width: 1171px;
    text-align: center;
}

.small-wrap .box-tittle-big {
    font-size: 40px;
    font-weight: 400;
    color: #3e454d;
    margin: 158px 0 99px 0;
    text-align: center;
}

.display-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
    box-sizing: border-box;
}

.remove-margin-top {
    margin-top: 0;
}

.free-trial-bottom {
    width: 100%;
    height: 331px;
    background-color: #f7f8fa;
    margin-top: 105px;
}

.free-trial-bottom .free-trial-bottom-text {
    font-size: 40px;
    font-weight: 400;
    color: #3e454d;
    margin-bottom: 35px;
}

.display-sb .box-item {
    width: 160px;
    height: 106px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#box2,#box3 {
    padding: 0;
}
.display-sb .box-item .box-text {
    color: #000000A6;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    /*font-family: 'PingFang', Arial, sans-serif;*/
    font-size: 14px;
}

.box-tittle {
    font-size: 14px;
    color: #000000A6;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    margin: 0 22px 15px 22px;
}

.box-description {
    font-size: 18px;
    color: #666;
    margin: 0 22px 29px 22px;
}

.box-description2 {
    font-size: 16px;
    color: #999;
    text-align: center;
    margin: 0 22px 29px 22px;
}

.box {
    position: relative; /* 设置相对定位 */
    width: 318px;
    height: 440px;
    border: 1px solid #e5e5e5;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 50px;
    margin-right: 20px;
    transition: all 0.3s;
    padding-top: 31px;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border: 1px solid #d02726;
    }
}

.flex-justify-center {
    display: flex;
    justify-content: center;
}

.flex-align-center {
    display: flex;
    align-items: center;
}

.flex-justify-start {
    display: flex;
    justify-content: flex-start;
}

.flex-justify-between {
    display: flex;
    justify-content: space-between;
}

.flex-align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.box-img {
    position: absolute; /* 设置绝对定位 */
    bottom: 0; /* 底部对齐 */
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.footer {
    width: 100%;
    height: 344px;
    background-color: #2D353F;
}

.wrap {
    width: 1200px;
    margin: auto;
    min-width: 1200px;
}

.img-footer {
    width: 100px;
    height: 36.83px;
}

.menu {
    display: flex;
    align-items: center;
}

.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.menu ul li {
    margin-right: 88px;
}

.menu ul li:last-child {
    margin-right: 0;
}

.menu ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-family: Arial, sans-serif;
    transition: color 0.3s;
}

.menu ul li a:hover {
    color: #d02726;
}

.footer-description {
    color: #fff;
    width: 1200px;
    height: 132px;
    margin-top: 31px;
    display: flex;
}

.footer-description-left {
    height: 132px;
}

.footer-description-right {
    display: flex;
    align-items: center;
}

.footer-left-item {
    margin-bottom: 20px;
}

.footer-left-item:hover {
    color: #d02726;
}

.footer-left-img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.email-input {
    width: 346px;
    height: 24px;
    padding: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
    margin-right: 10px;
}

.apply-btn {
    width: 180px;
    height: 36px;
    background-color: #d02726;
    color: #fff;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

.apply-btn:hover {
    background-color: #b02421;
}

.footer-code {
    color: #ffffff;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    margin-bottom: 8px;
}

.footer-code span {
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    margin-right: 6px;
}

@media (max-width: 1651px) and (orientation: portrait) {
    .small-wrap {
        position: relative;
        padding: 0vw 0 0vw!important;
        width: 100vw!important;
        margin: auto;
        max-width: 100vw!important;
        min-width: 100vw!important;
        animation: slideInBottom 1s ease forwards;
        display: flex;
        align-content: center;
        justify-content: center;
    }
    .small-wrap .box {
        position: relative;
        width: 28vw;
        height: calc(35vw * (440 / 318));
        border: 1px solid #e5e5e5;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 2.86vw;
        margin-right: 1.07vw;
        transition: all 0.3s;
        padding-top: 31px;
    }
    .small-wrap .box .box-tittle {
        font-size: 8px;
        color: #000000A6;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        margin: 0 2.29vw 1.07vw 2.29vw;
    }
    .small-wrap .box .box-description {
        font-size: 8px;
        color: #666;
        margin: 0 2.29vw 1.07vw 2.29vw;
    }
    .small-wrap .box .box-description2 {
        font-size: 8px;
        color: #999;
        text-align: center;
        margin: 0 2.29vw 1.07vw 2.29vw;
    }
    .small-wrap .box-tittle-big {
        font-size: 5vw;
        font-weight: 400;
        color: #3e454d;
        margin: 17.43vw 0 10.86vw 0;
        text-align: center;
    }
    .small-wrap .move-box {
        width: 75vw!important;
        height: 10.67vw!important;
    }
    .small-wrap .move-box .box-item {
        width: 16vw;
        height: 10.67vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .small-wrap .move-box .box-item img {
        width: 7.2vw!important;
        height: 7.2vw!important;
        margin-bottom: 3.11vw!important;
    }
    .small-wrap .move-box .box-item .box-text {
        color: #000000A6;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        font-size: 1.8vw!important;
        width: 17vw!important;
    }
    .free-trial-bottom {
        width: 100%;
        height: 46.43vw;
        background-color: #f7f8fa;
        margin-top: 14.76vw;
    }
    .free-trial-bottom .free-trial-bottom-text {
        font-size: 5.63vw;
        font-weight: 400;
        color: #3e454d;
        margin-bottom: 4.93vw;
    }
    .right-box .right-box-img {
        width: 100px;
        height: 100px;
    }
    .left-box .title {
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
        font-weight: 500;
        color: #3e454d;
        line-height: 30px;
        text-align: center;
    }
    .left-box .description {
        font-size: 14px;
        color: #686865;
        line-height: 24px;
        margin-bottom: 10px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
    .big-box {
        width: 100%;
        height: 357px;
        margin-bottom: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: url("../../../public/home-banner.png") no-repeat center;
        background-size: 300px 300px;
    }
    .big-box .left-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .big-box .left-box .title {
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 10px;
        font-weight: 500;
        color: #3e454d;
        line-height: 30px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
    .big-box .right-box {
        display: none;
    }
    /*#box2 .box:nth-child(1){*/
    /*    background: url("../../../public/index1.png")*/
    /*    no-repeat center;*/
    /*    background-size: 100% 100%;*/
    /*}*/
    #box2 .box-description2 {
        display: none;
    }
}

